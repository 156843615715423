const collapsibles = document.querySelectorAll(".collapsible");
collapsibles.forEach((item) =>
  item.addEventListener("click", function () {
    this.classList.toggle("collapsible--expanded");
  })
);

document.addEventListener("DOMContentLoaded", function () {
  const form = document.getElementById("visitorForm");
  const visitorCount = document.getElementById("visitorCount");
  let count = localStorage.getItem("visitorCount") || 0;

  // Update visitor count
  count = parseInt(count) + 1;
  localStorage.setItem("visitorCount", count);
  visitorCount.textContent = count;

  // Initialize survey results in localStorage if they don't exist
  if (!localStorage.getItem("surveyResults")) {
    localStorage.setItem(
      "surveyResults",
      JSON.stringify({
        facebook: 0,
        instagram: 0,
        linkedin: 0,
        event: 0,
        other: 0,
      })
    );
  }
  // Handle form submission
  form.addEventListener("submit", function (e) {
    e.preventDefault();

    const source = document.getElementById("sourceInput").value;
    const comment = document.getElementById("commentInput").value;

    // Here you would typically send this data to your backend
    // For now, we'll just show a success message
    const formData = {
      source: source,
      comment: comment,
      timestamp: new Date().toISOString(),
    };

    console.log("Form submitted:", formData);

    // Show success message
    showMessage("Thank you for your feedback!");

    // Reset form
    form.reset();
  });

  function showMessage(message) {
    const messageDiv = document.createElement("div");
    messageDiv.className = "feedback-message";
    messageDiv.textContent = message;

    form.parentNode.insertBefore(messageDiv, form.nextSibling);

    // Remove message after 3 seconds
    setTimeout(() => {
      messageDiv.remove();
    }, 3000);
  }
});
